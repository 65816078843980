void 0;
var cemf = cemf || {
    js: {}
};

cemf.js.Utility = function () {
}

cemf.js.Utility.prototype.ajaxResponseBaseTime = 3;
cemf.js.Utility.prototype.ajaxResponseRequestError = "<div class='message error icon-warning'>Desculpe mas não foi possível processar sua requisição...</div>";

cemf.js.Utility.prototype.animateMenuBar = false;

cemf.js.Utility.prototype.sizeTablet = 1024;

cemf.js.Utility.prototype.messageCOVID = "COVID19";

cemf.js.Utility.prototype.url_current = function () {
    var url = window.location.href;
    if (url.match(/localhost/)) {
        return 'https://www.localhost/projetos/CEMF/khangoo';
    }else if (url.match(/automacaohouseonline/)) {
        return 'https://www.automacaohouseonline.com.br/khangoo';
    }
    return 'https://www.khangoo.com.br';
}

cemf.js.Utility.prototype.date_fmt_br = function (_date) {
    var text = "" + _date;
    var $new_date = text.replace(" ", "T");
    var $date = new Date($new_date);
    var result = $date.getDay() + "/" + $date.getMonth() + "/" + $date.getFullYear();
    return (result);
}

cemf.js.Utility.prototype.dateToPT = function (date) {
    return date.split('-').reverse().join('/');
}

cemf.js.Utility.prototype.formatNumberPT = function (number) {
    return number.toLocaleString('pt-br', {minimumFractionDigits: 2});
}

/* Verifica se a TELA é de TABLET/CELULAR ou não, */
cemf.js.Utility.prototype.isCelular = function () {
    return (this.screenSize() < this.sizeTablet) ? true : false;
}

/* Verifica se existe um seletor*/
cemf.js.Utility.prototype.exists = function (selector) {
    return $(selector).length > 0 ? true : false;
}

/* Captura o Tamanho da tela, retornando a largura*/
cemf.js.Utility.prototype.screenSize = function (selector) {
    var w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName('body')[0],
        x = w.innerWidth || e.clientWidth || g.clientWidth,
        y = w.innerHeight || e.clientHeight || g.clientHeight;
    return x;
}

cemf.js.Utility.prototype.translate = function (text) {
    var translate = text;
    switch (text) {
        case 'captured':
            translate = "CAPTURADO";
            break;
        case 'used':
            translate = "UTILIZADO";
            break;
        case 'expired':
            translate = "EXPIRADO";
            break;
        case 'blocked':
            translate = "BLOQUEADO";
            break;
    }
    return translate;
}

/*****************************
 *  Função de Validação do NICKNAME
 *********************/
cemf.js.Utility.prototype.checkedNickname = function (selector) {
    var dataset = {"nickname": selector.val()};
    void 0;
    $.post(selector.data("push"), dataset, function (response) {
        void 0;
        if (response.message) {
            $('.ajax_request')
                .removeClass("icon-ban icon-check")
                .addClass((response.error) ? "icon-ban" : "icon-check")
                .text((response.message));
        }
    }, "json");
}

/*****************************
 *  Função de SCROOL CONFORME DATA-ROLE
 *********************/
cemf.js.Utility.prototype.scrollPage = function (selector) {
    void 0;
    self = this;
    var role = $(selector).data('role');
    if (self.exists($(role))) {
        pos = $(role).offset().top - 100;
    } else {
        pos = 0;
    }
    $('html, body').animate({
        scrollTop: pos
    }, 400, 'linear');

    $(".j_menu_mobile_close").trigger("click");

}

/*****************************
 *  FUNÇÃO DE OPEN DIV/CLOSE PARA QUESTIONS
 *********************/
cemf.js.Utility.prototype.openQuestions = function (selector) {
    var open = $(selector).data("question-open");
    $(selector).toggleClass('rotate');
    $("[data-" + open + "]").animate({'height': 'toggle'}, 300, function () {
    });
}


/********************************
 * CRIAÇÃO DO CARD DO PAINEL DE OFERTAS COM DESCONTO
 *****************************/
cemf.js.Utility.prototype.createOfferCard = function ($offer, $mode) {
    var self = this;
    void 0;

    var card = $("<li></li>");
    var a = $("<a></a>");
    if ($mode == 'admin') {
        a.attr('href', self.url_current() + "/app/admin/oferta/" + $offer.id);
    } else {
        a.attr('href', self.url_current() + "/app/oferta/" + $offer.id);
    }

    var article = $("<article></article>");
    article.addClass("card v2-card_container -white");

    /***************
     *  RESPONSAVEL PELO LAYOUT DO TOPO
     ****************/
    var card_top = $("<div></div>");
    card_top.addClass("card_top animated");


    /** IMAGEM PRODUTO */
    var div_top_product = $("<div></div>");
    div_top_product.addClass("card_top_product");
    var img_top_product = $("<img/>");
    img_top_product.attr("src", self.url_current() + "/storage/" + $offer.photo_offer);
    div_top_product.append(img_top_product);

    /** CONTO COM VOCÊ **/
    var div_top_extra = $("<div></div>");
    if ($offer.extra != null) {
        div_top_extra.addClass("tag_extra btn-round -red pl-25 cf-text-4 animated slideInLeft faster delay-1s")
        var p_top_extra = $("<p></p>");
        p_top_extra.addClass("cf-text-2 --white upper").text("CONTO COM VOCÊ");
        div_top_extra.append(p_top_extra);
    }

    /* Nome da empresa*/
    var div_top_name = $("<div></div>");
    div_top_name.addClass("card_target bg-op-black-03 animated slideInUp fast ");
    var p_top_name = $("<p></p>");
    p_top_name.addClass("cf-text-1 --white upper").text($offer.name);
    div_top_name.append(p_top_name);

    /* Preço*/
    var div_top_price = $("<div></div>");
    div_top_price.addClass("card_bottom_price p-5 animated slideInRight fast delay-1s");
    var p_top_price_previous = $("<p></p>");
    var p_top_price = $("<p></p>");
    if ($offer.value_previous != null) {
        p_top_price_previous
            .addClass("cf-text-2 --gray scratch")
            .html("de : <span>R$ " + $offer.value_previous + "</span>");
        div_top_price.append(p_top_price_previous);
    }
    p_top_price
        .addClass("cf-text-5 --black strong ls--4")
        .html("Por : R$ " + self.formatNumberPT($offer.value));

    div_top_price.append(p_top_price);
    card_top.append(div_top_product, div_top_extra, div_top_name, div_top_price);


    /**************
     *  RESPONSAVEL PELO LAYOUT DO RODAPE
     */
    var card_bottom = $("<div></div>");
    card_bottom.addClass("card_bottom ml-10 pt-10 mr-10");

    /* Titulo da Oferta */
    var div_title = $("<div></div>");
    div_title.addClass("title");
    var h1_title = $("<h1></h1>");
    h1_title
        .addClass("cf-text-2 --black bold upper")
        .text($offer.title);
    var p_title = $("<p></p>");
    p_title
        .addClass("cf-text-1 --gray op-6 first-upper m-3")
        .text($offer.district+"-"+$offer.city+"-"+$offer.state);
    div_title.append(h1_title,p_title);

    /* CARD FOOTER */
    var div_card_footer = $("<div></div>");
    div_card_footer.addClass("card_footer animated fadeIn fast delay-1s");

    /* PRICE OFF*/
    var div_price_off = $("<div></div>");
    div_price_off.addClass("price_off animated bounceIn fast delay-2s");
    if($offer.value_previous != null){
        var $percent = 0;
        if ($offer.value_previous > $offer.value){
            $percent = (1 - (parseFloat($offer.value) / parseFloat($offer.value_previous))) * 100;
        }
        var p_price_off = $("<p></p>");
        p_price_off
            .addClass("cf-text-6 ls--4 --black bold")
            .text(parseInt($percent) + "% off")
    }
    div_price_off.append(p_price_off);

    /* SOCIAL*/
    var div_social = $("<div></div>");
    div_social.addClass("card_social mr-5 ml-5");

    // quantidade de pessoas
    var div_social_peaple = $("<div></div>");
    div_social_peaple.addClass("offer_peaple");
    var img_social_peaple = $("<img/>");
    img_social_peaple.attr("src", self.url_current() + "/shared/images/icons/ic_peaple.png");
    var p_social_peaple = $("<p></p>");
    p_social_peaple.addClass("cf-text-1 --gray black ml-5").text($offer.vouchers_captured);
    div_social_peaple.append(img_social_peaple,p_social_peaple);

    // data de expiração
    var div_social_expiration = $("<div></div>");
    div_social_expiration.addClass("offer_expiration");
    var img_social_expiration = $("<img/>");
    img_social_expiration.attr("src", self.url_current() + "/shared/images/icons/ic_event_black_12dp.png");
    img_social_expiration.css("width","12px");
    var p_social_expiration = $("<p></p>");
    p_social_expiration.addClass("cf-text-1 --gray bold ml-5").text(self.dateToPT($offer.expiration));
    div_social_expiration.append(img_social_expiration,p_social_expiration);

    div_social.append(div_social_peaple,div_social_expiration);
    div_card_footer.append(div_price_off,div_social);

    card_bottom.append(div_title,div_card_footer);

    article.append(card_top, card_bottom);

    /**  FORMATAÇÃO DA QUANTIDADE DISPONIVEL */

    $missing = ($offer.voucher - $offer.vouchers_captured);
    if ($missing <= 10){
        var div_restam = $("<div></div>");
        div_restam.addClass("restam animated bounceIn fast delay-2s");
        p_restam = $("<p></p>");
        p_restam.addClass("cf-text-2 --white mr-5");
        div_circle = $("<div></div>");
        div_circle.addClass("circle -blue-light");
        p_missing = $("<p></p>");
        p_missing
            .addClass("cf-text-6 ls--3 --white bold center")
            .text($missing);
        div_circle.append(p_missing);
        div_restam.append(p_restam,div_circle);

        article.append(div_restam);
    }

    a.append(article);
    card.append(a);
    return card;
}

/********************************
 * CRIAÇÃO DO CARD DO PAINEL DE OFERTAS COM DESCONTO
 *****************************/
cemf.js.Utility.prototype.createOfferCardDouble = function ($offer,$mode) {
    var self = this;
    void 0;

    var card = $("<li></li>");
    var a = $("<a></a>");
    if ($mode == 'admin') {
        a.attr('href', self.url_current() + "/app/admin/oferta/" + $offer.id);
    } else {
        a.attr('href', self.url_current() + "/app/oferta/" + $offer.id);
    }

    var article = $("<article></article>");
    article.addClass("card v2-card_container -white double");

    /***************
     *  RESPONSAVEL PELO LAYOUT DO TOPO
     ****************/
    var card_top = $("<div></div>");
    card_top.addClass("card_top animated double");


    /** IMAGEM PRODUTO */
    var div_top_product = $("<div></div>");
    div_top_product.addClass("card_top_product");
    var img_top_product = $("<img/>");
    img_top_product.attr("src", self.url_current() + "/storage/" + $offer.photo_offer);
    div_top_product.append(img_top_product);

    /** CONTO COM VOCÊ **/
    var div_top_extra = $("<div></div>");
    if ($offer.extra != null) {
        div_top_extra.addClass("tag_extra btn-round -red pl-25 cf-text-4 animated slideInLeft faster ");
        var p_top_extra = $("<p></p>");
        p_top_extra.addClass("cf-text-2 --white upper").text("CONTO COM VOCÊ");
        div_top_extra.append(p_top_extra);
    }

    /* Nome da empresa*/
    var div_top_name = $("<div></div>");
    div_top_name.addClass("card_target bg-op-black-03 animated slideInUp slow double");
    var p_top_name = $("<p></p>");
    p_top_name.addClass("cf-text-2 --white upper light").text($offer.name);
    div_top_name.append(p_top_name);


    card_top.append(div_top_product, div_top_extra, div_top_name);


    /* SOCIAL*/
    var div_social = $("<div></div>");
    div_social.addClass("card_social double animated fadeIn fast delay-1s");
    var div_social_peaple = $("<div></div>");
    div_social_peaple.addClass("offer_peaple");
    var img_social_peaple = $("<img/>");
    img_social_peaple.attr("src", self.url_current() + "/shared/images/icons/ic_peaple.png");
    var p_social_peaple = $("<p></p>");
    p_social_peaple.addClass("cf-text-1 --gray black ml-5").text($offer.vouchers_captured);
    div_social_peaple.append(img_social_peaple,p_social_peaple);
    div_social.append(div_social_peaple);


    var card_bottom = $("<div></div>");
    card_bottom.addClass("card_bottom ml-10 pt-10 mr-10 double");
    var div_title = $("<div></div>");
    div_title.addClass("title");
    var h1_title = $("<h1></h1>");
    h1_title
        .addClass("cf-text-3 --black bold upper")
        .text($offer.title);
    div_title.append(h1_title);
    card_bottom.append(div_title);


    /* ICON DOUBLE */
    var div_icon_double = $("<div></div>");
    div_icon_double.addClass("icon_double animated bounceIn fast delay-1s");
    var img_icon_double = $("<img/>");
    img_icon_double.attr("src", self.url_current() + "/shared/images/icons/v2/double.png");
    div_icon_double.append(img_icon_double);

    article.append(card_top, div_social, card_bottom,div_icon_double);

    /**  FORMATAÇÃO DA QUANTIDADE DISPONIVEL */

    a.append(article);
    card.append(a);
    return card;
}


/********************************
 * CRIAÇÃO DO VOUCHER PARA VALIDAÇÃO
 *****************************/
cemf.js.Utility.prototype.createVoucherCard = function (params) {
    var self = this;
    var article = $("<article></article>");

    // IMAGEN
    article.addClass("card voucher_item mt-5 -white validate");
    var div_card_image = $("<div></div>");
    div_card_image.addClass("card_image");
    var img_user = $("<img/>");
    void 0;
    img_user.attr("src", params.photo_user);
    div_card_image.append(img_user);

    //INFO
    var div_card_info = $("<div></div>");
    div_card_info.addClass("card_info");
    var h2 = $("<h2></h2>");
    h2.addClass("cf-text-4 upper op-2").text(params.code);
    var p1 = $("<p></p>");
    p1.addClass("cf-text-1 upper").text(params.nickname);
    var p2 = $("<p></p>");
    p2.addClass("cfcf-text-3 upper bold").text('R$ ' + self.formatNumberPT(params.value));
    var p3 = $("<p></p>");
    p3.addClass("cf-text-4 strong upper " + params.status).text(self.translate(params.status));
    var p4 = $("<p></p>");
    p4.addClass("cf-text-1 icon-reply-all ml-5");

    if (params.status == 'captured') {
        p4.text(self.date_fmt_br(params.created_at));
    } else if (params.used_in == null) {
        p4.text(self.date_fmt_br(params.created_at));
    } else if (params.status == 'expired') {
        p4.text(self.dateToPT(params.expiration));
    } else if (params.status == 'blocked') {
        p4.text("--");
    } else {
        p4.text(self.date_fmt_br(params.used_in));
    }

    // Button Validate
    div_button = $("<div></div>");
    div_button.addClass("buttom_validate mt-5 flex");
    div_button.css({
        'padding':'10px 20px'
    });


    if (params.status == 'captured') {
        a_buttom = $("<a></a>");
        a_buttom.addClass("btn btn-default info upper -red");
        a_buttom.css({
            'padding':'10px 20px'
        });
        a_buttom.attr({
            'href': params.url,
            'title': 'Validar Voucher',
        }).text("VALIDAR");
        div_button.append(a_buttom);
    }


    div_card_info.append(h2, p1, p2, p3, p4, div_button);

    // OFFER
    var div_card_action = $("<div></div>");
    div_card_action.addClass("card_action");
    var a_card = $("<a></a>");
    a_card.attr({
        'href': '#'
    });

    var div_card_action_image = $("<div></div>");
    div_card_action_image.addClass("card_action_image m-5");
    var img_offer = $("<img/>");
    img_offer.attr({
        'src': params.photo_offer
    });
    div_card_action_image.append(img_offer);
    var p_offer = $("<p></p>");
    p_offer.addClass("cf-text-1 upper --black").text(params.title);
    a_card.append(div_card_action_image, p_offer);


    div_card_action.append(a_card);

    article.append(div_card_image, div_card_info, div_card_action);
    return article;
}

/*************************
 *  VALIDAR CEP
 *************************/
cemf.js.Utility.prototype.searchZipCode = function (zipcode) {
    return {
        onComplete: function (zipcode) {
            $.ajax({
                url: 'https://viacep.com.br/ws/' + zipcode.replace(/\D/g, '') + '/json/',
                type: 'GET',
                dataType: 'json',
            })
                .done(function (result) {
                    void 0;
                    void 0;
                    if (result.erro) {

                    } else {
                        $('#street').val(result.logradouro);
                        $("label[for='street']").addClass("inputMagicUp");
                        $('#complement').val(result.complemento).addClass("inputMagicUp");
                        $("label[for='complement']").addClass("inputMagicUp");
                        $('#district').val(result.bairro).addClass("inputMagicUp");
                        $("label[for='district']").addClass("inputMagicUp");
                        // citymodifyCEP = result.localidade
                        $('#state')
                            .val(result.uf)
                            .trigger('change')
                            .addClass("inputMagicUp");
                        $("label[for='state']").addClass("inputMagicUp");

                        $('#city').val(result.localidade).addClass("inputMagicUp");
                        $("label[for='city']").addClass("inputMagicUp");
                    }

                })
                .fail(function (e, textStatus, all) {
                    //console.log("error");
                    void 0;
                    void 0;
                    void 0;
                })
        }
    }
}

/*****************************
 *  DISPARO DE MENSAGENS
 *********************/
cemf.js.Utility.prototype.ajaxMessage = function (message, time) {
    var ajaxMessage = $(message);

    ajaxMessage.append("<div class='message_time'></div>");
    ajaxMessage.find(".message_time").animate({"width": "100%"}, time * 1000, function () {
        //console.log($(this).parents(".message"));
        $(this).parents(".message").addClass('animated slideOutRight').fadeOut(400);
    });

    $(".ajax_response").append(ajaxMessage);
    ajaxMessage.effect("bounce");
}

cemf.js.Utility.prototype.messageStart = function () {
    var self = this;
    $(".ajax_response .message").each(function (e, m) {
        self.ajaxMessage(m, self.ajaxResponseBaseTime + 1);
    });

    $(".ajax_response").on("click", ".message", function (e) {
        $(this).effect("bounce").fadeOut(1);
    });
}

/***************************************
 *  EVENTO POST PADRÃO DOS FORMULÁRIOS
 ***************************************/
cemf.js.Utility.prototype.startPostForm = function ($time) {
    var self = this;
    self.messageStart();
    $("form:not('.ajax_off')").submit(function (e) {
        e.preventDefault();
        var form = $(this);
        var load = $("[data-modal-loading]");
        var flashClass = "ajax_response";
        var flash = $("." + flashClass);

        form.ajaxSubmit({
            url: form.attr("action"),
            type: "POST",
            dataType: "json",
            beforeSend: function () {
                void 0;
                load.fadeIn('fast');
            },
            uploadProgress: function (event, position, total, completed) {
                var loaded = completed;
                var load_title = $("[data-info]");
                load_title.text("Enviando (" + loaded + "%)");

                form.find("input[type='file']").val(null);
                if (completed >= 100) {
                    load_title.text("Aguarde...");
                }
            },
            success: function (response) {
                void 0;
                void 0;

                //redirect
                if (response.redirect) {
                    window.location.href = response.redirect;
                }

                //reload
                if (response.reload) {
                    location.reload();
                }

                //message
                if (response.message) {
                    self.ajaxMessage(response.message, ($time != null) ? $time : self.ajaxResponseBaseTime);
                }
            },
            complete: function (response) {
                void 0;

                if (response.reload == null) {
                    load.fadeOut('fast');
                }

                if (form.data("reset") === true) {
                    form.trigger("reset");
                }

                $('html, body').animate({scrollTop: 0}, 50);
            },
            error: function (event) {
                load.fadeOut('fast', function () {
                    self.ajaxMessage(self.ajaxResponseRequestError, 5);
                });
            }
        });
    })
}

cemf.js.Utility.prototype.startWeb = function () {
    // mobile menu open
    var self = this;
    self.start();
    self.startPostForm(); // Carregando o evento submit nos formulários

    $(".j_menu_mobile_open").click(function (e) {
        e.preventDefault();
        $(".j_menu_mobile_tab").css("left", "auto").fadeIn(1).animate({"right": "0"}, 200);
    });

    // mobile menu close
    $(".j_menu_mobile_close").click(function (e) {
        e.preventDefault();
        $(".j_menu_mobile_tab").animate({"left": "100%"}, 200, function () {
            $(".j_menu_mobile_tab").css({
                "right": "auto",
                "display": "none"
            });
        });
    });
}

cemf.js.Utility.prototype.startApp = function () {
    var self = this;
    self.start();
    self.startPostForm(); // Carregando o evento submit nos formulários

    /*
   * IMAGE RENDER
   */
    $("[data-image]").change(function (e) {
        void 0;
        var changed = $(this);
        var file = this;
        var $thumb = $("[data-thumb-view='" + $(this).attr('id') + "']");
        //console.log($thumb);

        if (file.files && file.files[0]) {
            var render = new FileReader();

            render.onload = function (e) {
                $("[data-image-view]").fadeTo(100, 0.1, function () {
                    $(this).attr("src", e.target.result)
                        .fadeTo(100, 1);
                });
                $($thumb).attr('src', e.target.result);
            };
            render.readAsDataURL(file.files[0]);
        }
    });

  /*
  * IMAGE RENDER
  */
    $("[data-image-cover]").change(function (e) {
        void 0;
        var changed = $(this);
        var file = this;
        var $thumb = $("[data-thumb-view='" + $(this).attr('id') + "']");

        if (file.files && file.files[0]) {
            var render = new FileReader();

            render.onload = function (e) {
                $("[data-image-view-cover]").fadeTo(100, 0.1, function () {
                    $(this).attr("src", e.target.result)
                        .fadeTo(100, 1);
                });
                $($thumb).attr('src', e.target.result);
            };
            render.readAsDataURL(file.files[0]);
        }
    });

    /*************************
     * MENU COMPANY OPEN/CLOSE
     *************************/
    $(".kg-menu-image-profile").on('click', function () {
        void 0;
        $(".kg-option.fixed").removeClass("in-scale");
        $(".kg-option-user").toggleClass("in-scale");
    })

    /**************
     *  MENU HAMBURG CLICK
     ****************/
    $(".kg-menu-image-mobile").on('click', function () {
        $(".kg-option-user").removeClass("in-scale");
        $(".kg-option.fixed").toggleClass("in-scale");
    })

    /********************************
     *   FECHAR MENU QUANDO CLICA NO CONTENT
     ********************************/
    $(".kg-content").on("click", function () {
        $(".kg-option.fixed").removeClass("in-scale");
        $(".kg-option-user").removeClass("in-scale");
    });


    /***************
     * MONITOR SCROLL
     ***************/
    $(document).on('scroll', function () {
        onSroll();
    });
    onSroll();

    function onSroll() {
        if (self.exists('.scroll-off')) {
            return;
        }

        var posicaoScroll = $(document).scrollTop();

        /*Menu Float Shadow*/
        var main = $("main").offset().top;

        if (posicaoScroll > (main - 60)) {
            $(".kg-menu-bar").addClass("color-primary shadow-top-z-8");
            $(".kg-menu-image-mobile").removeClass("animated shake");
        } else {
            $(".kg-menu-bar").removeClass("color-primary shadow-top-z-8");
        }

        if (posicaoScroll < 20 && self.animateMenuBar == true) {  // saida do menu Float
            $(".kg-option[data-change='yes']")
                .removeClass('fixed in-scale card')
                .addClass('float');
            $(".kg-menu-image-mobile").removeClass("change-icon");
            $(".kg-menu-image-mobile")
                .addClass("fadeOut")
                .removeClass("change-icon");
            self.animateMenuBar = false;
        } else if (posicaoScroll > 219 && self.animateMenuBar == false) {  // saida do menu Float
            $(".kg-option[data-change='yes']").removeClass('float').addClass('fixed card');
            $(".kg-menu-image-mobile")
                .removeClass("fadeIn")
                .addClass("change-icon");
            self.animateMenuBar = true;
        }
    }

    /********************
     *   SPLASH
     */
    $("[data-splash-close]").on("click", function () {
        $("[data-splash]").animate({
            'opacity': '0',
            'top': "110vw"
        }, 400, function () {
            $(this).remove();
        })
    });

    $("[data-splash]").on("click", function (e) {
        e.preventDefault();
    });

    if (self.exists("[data-splash]")) {
        setTimeout(function () {
            $("[data-splash]").animate({
                'opacity': '0',
                'top': "110vw"
            }, 400, function () {
                $(this).remove();
            });
        }, 4000);
    }
}

cemf.js.Utility.prototype.start = function () {
    var self = this;

    //self.messageStart();

    /* VERIFICAÇÃO DO NICKNAME*/
    $("[data-nickname-validator]").on("keyup", function () {
        self.checkedNickname($(this));
    });

    /* ROLAGEM DA TELA PELO MENU */
    $("[data-role]").on('click', function (e) {
        e.preventDefault();
        self.scrollPage($(this));
    });

    /* ABERTURA DAS REPOSTAS */
    $("[data-question]").on("click", function (e) {
        e.preventDefault();
        var id = $(this).data('question');
        if ($("[data-answer =" + id + "]").is(":visible")) {
            $(this).find('a').html("+");
        } else {
            $(this).find('a').html("-");
        }
        $("[data-answer =" + id + "]").animate({'height': 'toggle'}, 300, function () {
        });
        //self.openQuestions($(this));
    });

    $("[data-question-open]").on('click', function (e) {
        e.preventDefault();
        void 0;
        self.openQuestions($(this));
    });

    /***************
     * OPEN MODAL
     ***************/
    $("[data-close]").on('click', function () {
        var close = $(this).data("close");
        $("[data-" + close + "]").fadeOut('fast');
    })

    $("[data-modal-open]").on('click', function (e) {
        e.preventDefault();
        var open = $(this).data("modal-open");
        var close = $(this).data("modal-close");
        var info = $(this).data("modal-info");
        var href = $(this).attr("href");
        void 0;
        if (info != null)
            $("[data-info]").html(info);
        if (close != null)
            $("[data-" + close + "]").fadeOut(30);

        if (href != null && href != "#") {
            window.location.href = href;
        }

        $("[data-" + open + "]").fadeIn("fast");
    })


    /* LIMITE DE CARACTERES */
    //console.log("capturando");
    $("[data-limit]").keyup(function () {
        void 0;
        var limit = $(this).data('limit');
        var id = $(this).attr('id');
        var val = $(this).val();
        if (val.length >= limit) {
            $(this).val(val.substr(0, limit));
            val = $(this).val();
        }

        $("[data-limit-" + id + "]").text(val.length + "/" + limit);
    });


    /*************************
     *  INPUT MAGIC
     *************************/

    $(".input_magic:not('.ajax_off') input").on('keyup', function () {
        var name = $(this).attr('name');
        var label = $("label[for='" + name + "']");
        var input = $(this);
        void 0;

        if ($(input).val().length >= 1) {
            $(label).addClass("inputMagicUp");
            // $(this).parent().css('margin-top','10px');
        } else {
            $(label).removeClass("inputMagicUp");
            // $(this).parent().css('margin-top','3px');
        }
    })

    /*************************
     *  INPUT TEXTAREA
     *************************/

    $(".input_magic:not('.ajax_off') textarea").on('keyup', function () {
        var name = $(this).attr('name');
        var label = $("label[for='" + name + "']");
        var input = $(this);
        void 0;

        if ($(input).val().length >= 1) {
            $(label).addClass("inputMagicUp");
            // $(this).parent().css('margin-top','10px');
        } else {
            $(label).removeClass("inputMagicUp");
            // $(this).parent().css('margin-top','3px');
        }
    })

}


